<template>
  <div class="deformation">
    <div class="deformation_top">
      <div class="h2">《字体设计变形宝典》<span>读者专区</span></div>
      <div class="h6"><pre>{{ info.section }}</pre></div>
      <div class="p"><pre>{{ info.dictionaries }}</pre></div>
      <div class="p2">下滑至页面底部查看购书方式</div>
      <button @click="popStatusFun">点击绑定激活码</button>
      <div class="img"><img :src="info.pc_order" /></div>
    </div>
    <div class="case">
      <div class="head">变形宝典应用案例</div>
      <div class="img"><img :src="info.pc_cases" /></div>
    </div>
    <div class="mode">
      <div class="head">变形宝典购书方式</div>
      <div class="mode_con">
        <div class="mode_con_one" v-for="(item,index) of book" :key="index">
          <img :src="item.pc_thumb" />
        </div>
        
      </div>
    </div>
    <!-- 侧边导航 -->
    <sidebar ref="downtotal"></sidebar>
    <binding v-if="popStatus" :type="0"></binding>
  </div>
</template>

<script>
import sidebar from "../components/sidebar.vue";
import binding from "../components/binding.vue";
export default {
  name: "deformation",
  data() {
    return {
      popStatus: false,
      info: null,
      book: null,
    };
  },
  components: {
    sidebar,
    binding,
  },
  mounted() {
    this.getInfo();
    this.getBook();
  },
  methods: {
    getInfo() {
      var that = this;
      this.$api.POST(this.$face.baodianinfo, "", function (res) {
        that.info = res.data;
      });
    },
    getBook() {
      var that = this;
      this.$api.POST(this.$face.book_index, "", function (res) {
        that.book = res.data;
      });
    },
    popStatusFun(){
      if(!localStorage.getItem("access_token")){
                this.$parent.loginShow();
                return ;
       }

      this.popStatus = true
    },
    contactqq() {
      //window.open('tencent://message/?Menu=yes&uin=' + this.qq + '&Site=https://new.zitisheji.com');tencent://message/?Menu=yes&uin={$footer['qq']|default=''}&Service=300&sigT=45a1e5847943b64c6ff3990f8a9e644d2b31356cb0b4ac6b24663a3c8dd0f8aa12a595b1714f9d45
      //window.open('tencent://message/?uin='+this.qq+'&Site=https://new.zitisheji.com&Menu=yes');
      window.open(
        "http://dct.zoosnet.net/LR/Chatpre.aspx?id=DCT52374225&lng=cn"
      );
    },
    jumpFun(url, param) {
      let routeData = this.$router.resolve({
        name: url,
        query: {
          id: param,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 回到顶部
    topBackFun() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果

      let space = 50;
      if (top >= 1000 && top < 2000) {
        space = 50;
      } else if (top >= 2000 && top < 3000) {
        space = 80;
      } else if (top >= 3000 && top < 4000) {
        space = 110;
      } else if (top >= 4000 && top > 6000) {
        space = 150;
      } else if (top >= 6000 && top > 10000) {
        space = 180;
      }

      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
            space;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },

    // 获取距离右侧距离
    rightIfFun(i) {
      console.log(this.$refs["getRightRefs" + i][0].offsetLeft);
      if (
        window.innerWidth - this.$refs["getRightRefs" + i][0].offsetLeft <=
        521
      ) {
        this.showDirection = true;
      } else {
        this.showDirection = false;
      }
      this.activeIndex = i;
    },

    // 清空历史记录
    delHistoryFun() {
      this.history = [];
      localStorage.removeItem("history");
    },
  },
  activated() {
    console.log(this.scroll);
    this.$nextTick(() => {
      this.$parent.routerIndex = 4;
      this.$parent.showIndex = 1;
      //判断是否点击的导航，导航清空筛选项
      if (localStorage.getItem("clearAll") == 1) {
        this.navId = "";
        this.navIndex = 0;
        this.navList.forEach((element) => {
          element.index = 0;
          element.isShow = false;
        });
      }
    });
  },
  computed: {
    right1() {
      var left = 0;
      if (window.innerWidth - 1400 > 128) {
        left = ((window.innerWidth - 1400) / 2 - 64) / 2;
      } else {
        left = 0;
      }
      console.log(left);
      return left;
    },
  },
  watch: {
    "$store.state.userInfo"(newval) {
      if(localStorage.getItem("access_token")){
        this.$router.push({
           name: "deformation",
        });
        return ;
      }
    },
  }
};
</script>

<style scoped>
.deformation {
  height: 100%;
  overflow: hidden;
  background: url(../assets/images/bd_banner2.png) no-repeat top center;
  background-repeat: repeat-x;
  padding-bottom: 30px;
}
.deformation_top {
  padding-top: 100px;
}
.deformation_top .h2 {
  font-size: 50px;
  font-weight: 600;
  color: #333;
  text-align: center;
}
.deformation_top .h2 span {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 48px;
  display: inline-block;
  padding: 3px 5px 6px;
  border-radius: 6px;
  color: #fff;
  background: #333;
}
.deformation_top .h6 {
  font-size: 26px;
  color: #444;
  text-align: center;
  margin: 16px auto 31px;
}
.deformation_top .h6 pre{font-family: -apple-system,"MicrosoftYaHei,sourcehansanscn"; line-height: 36px;}
.deformation_top .p,
.deformation_top .p2 {
  color: #808080;
  text-align: center;
  margin-bottom: 6px;
  font-size: 20px;
}
.deformation_top .p2 {
  color: red;
  text-align: center;
  margin-bottom: 6px;
  font-size: 34px;
  font-weight: bold;
}
.deformation_top .p pre{font-family: -apple-system,"MicrosoftYaHei,sourcehansanscn";line-height: 32px;}
.deformation_top button {
  width: 341px;
  height: 66px;
  border-radius: 8px;
  border: none;
  font-size: 24px;
  color: #fff;
  background: #5857ff;
  display: block;
  margin: 50px auto 0;
  cursor: pointer;
  letter-spacing: 2px;
}
.deformation_top .img {
  text-align: center;
  margin-top: 15px;
}
.deformation_top .img img {
  margin: auto;
}

.head {
  width: 425px;
  height: 34px;
  margin: auto;
  font-size: 34px;
  position: relative;
  text-align: center;
}
.head::before,
.head::after {
  content: " ";
  width: 30px;
  height: 21px;
  background: url("../assets/images/icon_title@2x.png");
}
.head::before {
  position: absolute;
  left: 0;
  top: 0;
}
.head::after {
  position: absolute;
  right: 0;
  top: 0;
  transform: rotate(180deg);
}
.case {
  margin: 45px auto;
}
.case .img {
  margin: 25px 0;
}
.case .img img {
  margin: auto;
}
.mode {
  margin: 45px auto;
}
.mode .img {
  margin: 25px 0;
}
.mode .img img {
  margin: auto;
}
.mode_con{ text-align: center; margin-top: 20px;}
.mode_con .mode_con_one{ display: inline-block; margin: 0 35px;}
</style>